module.exports = {
    index:{
        title:'Data Management Platform',
        console:'Console',
        historydata:'History',
        thermalTrajectory:'Track'
    },
    login:{
        username:'username',
        password:'password',
        submit:'Submit',
        captcha: 'please input a captcha',
    },
    showAll:'All',
    today:'New',
    total:'All',
    active:'Alive',
    wooker:'最近一ss周',
    ten:'最近一周',
    month:'最近一周',
    todayA:'Consumption mileages',
    totalA:'Total Consumed Mileage in Past 12 Months',
    todayB:'Recharge mileages',
    totalB:'Total Top Up Mileage in Past 12 Months',
    console:{
        titleOne:{
            realtime:'Real time statistics',
            type1:'Total number of electric vehicles',
            type2:'Total number of power exchange cabinets',
            type3:'Total number of batteries',
            type4:'Total mileage',
            type5:'Total number of users',
            type6:'Total Power',
            type7:'Total order amount',
            type8:'Total orders',
            type9:'Total power change times',
        },
        titleTwo:{
            realday:'Real time today',
            type1:'Mileage',
            type2:'Newly activated electric vehicle',
            type3:'New online power exchange cabinet',
            type4:'New battery',
            type5:'Faulty equipment',
            type6:'Number of alarms',
            type7:"New orders",
            type8:'Total new orders',
            type9:'Electricity consumption',
            type10:'Times of power change',

        }
    },
    common:{
        tipTitle:'test',
        tipName:'test',
        tipContent:"test",
        tipAverage:"test",
        optionsele:{
            type1:'online',
            type2:'offline',    
        }
    },
    options:{
        elecar:'Electric vehicle online and offline',
        huan:'power exchange cabinet online and offline',
        battery:'Battery online and offline',
        elecar:'Mileage in recent seven days',
        cycling:'Cycling in recent seven days',
        orders:'Orders in recent seven days',
        hoursname:'24-hour power change peak',
        common:{
            type1:'online',
            type2:'offline',  
        },
        
    },
    optionsHis:{
        shebei:'Device module',
        add:'New add',
        startTime:'start Date',
        endTime:'end Date',
        startMonth:'start month',
        endMonth:'end month',
        to:'to',
        select:'Please select',
        day:'day',
        month:'month',
        common:{
            sameday:'sameday',
            total:'total'
        },
        shebeis:{
            type1:'Consumed Mileage/ Top Up Mileage',
            type2:'Motorcycles',
            type3:'Batteries',
            type4:'Swap Stations in Operation',
            type5:'Active Users/Registered Users',
            type6:'Top Up Order/Top Up Amount',
            type7:'Order amount',
            type8:'Power Consumption',
            type9:'Number of Swaps/Number of Swap Stations',
            type10:'Active Vehicles/Active Swap Vehicles',
            type11:'Top Up Package Distribution',
        },
        elecar:'Electric vehicle'
    },
    hotLang:{
        name:'Top 20 Driving Points in Past 30 Days',
        ranking:'ranking',
        frequency:'frequency',
        Points:'area',
        province: 'province',
        city: 'city',
        provinceTitle: 'Top 20 provincial power exchange times in the past 30 days',
        cityTitle: 'Top 20 urban power exchange times in recent 30 days',
        pointsTitle: 'Top 20 regional power exchange times in the past 30 days',
    },
    layer:{
        toggle:'Are you sure to switch languages?',
        tips:'Tips'    
    },
    button:{
        ok:'ok',
        cancel:'cancel'
    },

    // left1:'Active motorcycles / Mileages In 7 Days',
    // left1:'近七天活跃车辆/里程',
    // left2:' Swap Times In 7 Days',
    // left2:'近七天换电次数',
    // left3:'Recharge orders / total orders in 7 Days',
    // left3:'近七天充值的订单/订单总额',
    left1:'Mileage and Top Ups in the Past 6 Months',
    left1Explanation:'Monthly total mileage consumed/Monthly total top up mileage for the past 6 months',
    left2:'Active Vehicles and Active Swap Vehicles in the Past 6 Months',
    left2Explanation:'Motorcycles with daily riding records/Total vehicles with daily battery replacements in the past 6 months',
    left4: 'Number of battery swaps per day in the past 6 months/number of battery swapping and charging cabinets per day',
    left4Explanation: 'Average battery swaps/ Swap Stations that generate battery swaps every day per month in the past 6 months',
    left3:'Top Up Orders and Total Orders in the Past 6 Months',
    left3Explanation:'Monthly total top up orders/Monthly total top up value in the past 6 months',
    content1:'The Historical 24-hour Battery Swap Frequency',
    content1Explanation:'Cumulative Swaps in a 24-hour period',
    right1:'Real-time Data',
    right2:'Total Mileage',
    right3:"Today's Mileage",
    right4:'Total Top Up',
    right5:"Today's Amount",
    right6:'Orders',
    right7:"Today's Order",
    right8:'Total Cumulative Orders',


    right9:'Device Data',
    right10:'Motorcycles',
    right11:'Active',
    right12:'New',
    // right12:'设备数据',

    right13:'Motorcycle Online',
    right14:'Swap Sation Online',
    right15:'Battery Online',

    right16:'Others',
    right17:'Total Users',
    right18:'Power Consumption',

    right21:'Swap Times',
    right22:'Warnings:',
    right23:'Errors:',

    right19:'Batteries',
    right20:'Swap Sations',
    right25:'Cumulative Energy Used',
    right99:'Data Platform',
    right100:'CO2 Emissions Reduced',
    right26:'added yesterday',

    rightBottomOne:{
        batteryLocation:'battery inventory',
        scooterLocation:'scooter inventory',
        stationLocation:'station inventory',  
    },
    inventoryRatioInfo:{
        Warehouse: 'Warehouse(IDN)',
        Operation: 'Operation(IDN)',
        Maintenance: 'Maintenance(IDN)',
        Factory: 'Factory(CNA)',
        RD: 'R&D(CNA)'
    },
    vehicles: 'vehicles',
    times: 'times',
    orders: 'orders',
    bind: 'bind',
    Alive: 'Activated',
    online: 'In Operation',
    expense: 'expense',
    topup: 'topup',
    activeVehicles: 'active vehicles',
    swapVehicles: 'swap vehicles',
    swapFrequency: 'Swap frequencyIn',
    swapStations: 'Swap stations',
    swapFrequencyA: 'Swap frequency',
    swapStationsA: 'Swap stations',
    swapFrequencyB: 'Total Swaps in Past 12 Months',
    swapStationsB: 'Total Swap Stations in Past 12 Months',
    activeUsersA: 'New active users',
    registeredUsersA: 'New registered users',
    activeUsersB: 'Total Active Users',
    registeredUsersB: 'Total Users',
    orderAmountA: 'New Order amount',
    orderQuantityA: 'New Order quantity',
    orderAmountB: 'Total Top Up Amount in Past 12 Months',
    orderQuantityB: 'Total Orders in Past 12 Months',
    activationA: 'New activation',
    activationB: 'Activated Motorcycles',
    exchangeCabinet: 'All power exchange cabinet',
    exchangeCabinetOperation: 'All switching cabinets in operation',
    right24: 'Historical Battery Swap Frequency',
    yesterday: 'yesterday',
    number: '',
    scooterTotal: 'Total Motorcycles',
    activeVehiclesTotal: 'NActive Vehicles in Past 12 Months',
    swapVehiclesTotal: 'Active Swap Vehicles in Past 12 Months',
    swapStations: 'Swap station',
    many:'Top 10 battery swap cabinets with the most battery swaps in the past 60 days',
    few: 'Top 10 battery swap cabinets with the least number of battery swaps in the past 60 days',
    title1: 'Trajectory Distribution',
    title2: 'Vehicle Real-Time Distribution',
    title3: 'Swap Station Distribution',
    title4: 'Trajectory Heatmap',
    title5: 'Top Vehicle Location Points',
    vehicle: 'quantity',
    piece: 'quantity',
    title6: 'Top Swap Station Location Points',
    cabinet1: 'Three-port cabinet new',
    cabinet2: 'Eight-port cabinet new',
    cabinet3: 'Three-port cabinet total',
    cabinet4: 'Eight-port cabinet total',
    centerMap1: 'Vehicles',
    centerMap2: 'Point',
    centerMap3: 'Batteries',
    centerMap4: 'Stations',
    
    centerMap5: 'Only show point',
    centerMap6: 'Only show batteries',
    centerMap7: 'Only show vehicles',
    centerMap8: 'Only show stations',
    centerMap9: 'all',
    title7: 'Number of battery swaps Distribution in the past 30 days',
    title8: 'Number of battery swaps Ranking in the past 30 days'
}
