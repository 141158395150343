/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
 import QS from 'qs';//引入qs模块，用来序列化post类型的数据
 import store from '../store/index.js';// 先导入vuex,因为我们要使用到里面的状态对象
 import { Message} from 'element-ui';//消息提示ui库  Message.error(error.message)
 import foowwLocalStorage from "../utils/foow.js";
 //环境的切换
 if (process.env.NODE_ENV == 'development') {    
     axios.defaults.baseURL = '/api';
 } else if (process.env.NODE_ENV == 'debug') {    
     axios.defaults.baseURL = '';
 } else if (process.env.NODE_ENV == 'production') {    
    // axios.defaults.baseURL = 'https://data.swap.id/api'; 正式站
    //axios.defaults.baseURL = 'http://testdata.swap.id/data/api';//测试站
   // axios.defaults.baseURL = 'https://data.antunni.com/data/api';
   axios.defaults.baseURL = 'https://data.atnsmart.com/data/api';
 }
 
 //axios.defaults.baseURL = 'api';//跨域解决  api映射
 
 // 请求超时时间
 axios.defaults.timeout = 20000;
 
 // post请求头
 axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
 
 // 请求拦截器
 axios.interceptors.request.use(    
     config => {
         // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // console.log(' store.state.login.', store.state.login);
         let token = store.state.login.userData.token;   
        // console.log(' store.state.login.',token);
        // console.log(';;;',foowwLocalStorage.get('base-token'))
         if (localStorage.getItem('base-token')) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
             config.headers.token = foowwLocalStorage.get('base-token');
             
         //	console.log('interceptors config=',config)
         }
             
         //token && (config.headers.Authorization = token);        
         return config;    
     },    
     error => {        
         return Promise.error(error);    
     }
 )
 // 响应拦截器
 axios.interceptors.response.use(    
     response => {     
         if(response.status === 200){
                 return Promise.resolve(response.data);	   
             } else {  
                 return Promise.reject(response.data);        
             }       
     },
     // 服务器状态码status不是200的情况    
     error => {   
         console.log('服务器状态码不是200')
         if (error.response.status) {            
             switch (error.response.status) {                
                 // 401: 未登录                
                 // 未登录则跳转登录页面，并携带当前页面的路径                
                 // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                 case 401:                    
                     router.replace({                        
                         path: '/login',                        
                         query: { redirect: router.currentRoute.fullPath } 
                     });
                     break;
                 // 403 token过期                
                 // 登录过期对用户进行提示                
                 // 清除本地token和清空vuex中token对象                
                 // 跳转登录页面                
                 case 403:                     
                     // Toast({                        
                     //     message: '登录过期，请重新登录',                        
                     //     duration: 1000,                        
                     //     forbidClick: true                    
                     // });    
                     error.message = '登录过期，请重新登录'
                             
                     // 清除token                    
                     localStorage.removeItem('token');                    
                     store.commit('login/loginSuccess', null);                    
                     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                     setTimeout(() => {                        
                         router.replace({                            
                             path: '/login',                            
                             query: { 
                                 redirect: router.currentRoute.fullPath 
                             }                        
                         });                    
                     }, 1000);                    
                     break; 
                 // 404请求不存在                
                 case 404:                    
                     // Toast({                        
                     //     message: '网络请求不存在',                        
                     //     duration: 1500,                        
                     //     forbidClick: true                    
                     // });    
                     error.message = '网络请求不存在'			
                 break;                
                 // 其他错误，直接抛出错误提示                
                 default:                    
                     // Toast({                        
                     //     message: error.response.data.message,                        
                     //     duration: 1500,                        
                     //     forbidClick: true                    
                     // });   
                     error.message = error.response.data.message			 
             }     
             //Message.error(error.message)
             return Promise.reject(error.response);        
         }       
     }
 );
 
 export default axios;
 