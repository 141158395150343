import Vue from "vue"
import Router from "vue-router"
import foowwLocalStorage from "./utils/foow.js";
Vue.use(Router)
const router =  new Router({
    mode: 'history',
	routes:[
		{ path: '*', redirect: '/layout' },
		{
			path:"/layout",
			name:"layout",
			component: () => import("./views/layout.vue"),
			children:[
				// {
				// 	path:"/",
				// 	name:"testIndex",
				// 	component: () => import("./views/test/testIndex.vue")
				// },
				// {
				// 	path:"/testIndex",
				// 	name:"testIndex",
				// 	component: () => import("./views/test/testIndex.vue")
				// },
				{
					path:"/",
					name:"newIndex",
					component: () => import("./views/newIndex/index.vue")
				},
				{
					path:"/newIndex",
					name:"newIndex",
					component: () => import("./views/newIndex/index.vue")
				},
				{
					path:"/referee",
					name:"referee",
					component: () => import("./views/people/referee.vue")
				},
				{
					path:"/store",
					name:"store",
					component: () => import("./views/store/store.vue")
				},
				{
					path:"/sponsor",
					name:"sponsor",
					component: () => import("./views/sponsor/sponsor.vue")
				},
				{
					path:"/history",
					name:"history",
					component: () => import("./views/history/index.vue")
				},
				{
					path:"/hot",
					name:"hot",
					component: () => import("./views/hot/hot.vue")
				}
			]
		},
		{
			path:"/login",
			name:"login",
			component:() => import("./views/login/login.vue")
		}
	]
})
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 导航守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    //let token = localStorage.getItem('token');
	let token = foowwLocalStorage.get('base-token')
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});
export default router;