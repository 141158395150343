import {userLogin,outLogin} from "../../request/user.js"
import foowwLocalStorage from "../../utils/foow.js";
import axios from 'axios';
const state = {
	  userData:{},
		activeName: '1',
		electricityTotal: 0,
		reduceConsume: 0,
		oldElectricityTotal: 0,
		oldReduceConsume: 0,
	}
const getters = {}

const actions = {
	 //用户登录
	async userLoginToken({ commit }, form) {
		const auth = await axios({
			method: 'post',
			url: '/SysUser/login',
			headers: {
				"captcha-key": form.captcha_key
			},
			data: {
			  account: form.account,
			  password: form.password,
			  captcha: form.captcha
			}
		  }).then(res=>{
	        // console.log(res)
			return res;
		  })
	    // const auth = await userLogin(form);
	    //localStorage.setItem('token', auth.data.token)
		// console.log(auth)
		if(auth.errcode === 200){
			const data = auth.data;
			foowwLocalStorage.set('base-token',data.token, new Date().getTime() + (4 * 60 * 60 * 1000))
			//console.log(data)
			commit("SET_AUTH_DATA", data);	
		}
		return auth;
	},
	  //用户退出登录
	    outLoginToken({ commit }, form) {
	     //const auth = await outLogin();
		 console.log("sfsfs--------");
		 
		 localStorage.removeItem('base-token'); 
		 // console.log("sdfsdfsfs");
	  //    commit("SET_AUTH_DATA", "");
	     //return auth;
	   }
}

const mutations = {
	SET_AUTH_DATA(state,value){
		state.userData = value
	},
	setReduceConsume(state, value) {
		state.reduceConsume = value;
		// localStorage.setItem(process.env.ADMIN_TABS_SELECT_KEY, JSON.stringify(key));
	},
	setElectricityTotal(state, value) {
		state.electricityTotal = value;
		// localStorage.setItem(process.env.ADMIN_TABS_SELECT_KEY, JSON.stringify(key));
	},
	setOldReduceConsume(state, value) {
		state.oldReduceConsume = value;
		// localStorage.setItem(process.env.ADMIN_TABS_SELECT_KEY, JSON.stringify(key));
	},
	setOldElectricityTotal(state, value) {
		state.oldElectricityTotal = value;
		// localStorage.setItem(process.env.ADMIN_TABS_SELECT_KEY, JSON.stringify(key));
	},
	setActiveName(state, value) {
		state.activeName = value;
		// localStorage.setItem(process.env.ADMIN_TABS_SELECT_KEY, JSON.stringify(key));
	},
		
}
export default {
	namespaced:true,
	state,
	getters,
	actions,
	mutations
}