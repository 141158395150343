import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store/index.js'
import i18n from './i18n/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css'
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
	router,
	store,
	i18n,
  render: h => h(App),
}).$mount('#app')
