
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './../utils/zh.js';
import en from './../utils/en.js';

import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

function getCookie(name,defaultValue) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
    else
      return defaultValue;
  }

  const messages = {
    en: Object.assign(en, enLocale),
    zh: Object.assign(zh, zhLocale)
  }
  console.log(messages.zh)

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en', // 语言标识
    messages:messages
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
export default i18n;