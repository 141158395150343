// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
    index:{
        title:'数据管理平台',
        console:'控制台',
        historydata:'历史数据',
        thermalTrajectory:'热力轨迹图'
    },
    login:{
        username:'username',
        password:'password',
        submit:'Submit',
        captcha: 'please input a captcha'
    },
    showAll:'全部',
    wooker:'最近一周',
    ten:'最近一周',
    month:'最近一周',
    today:'新增',
    active:'活跃',
    total:'总数',
    todayA:'消费里程',
    totalA:'近12个月消费里程总数',
    todayB:'充值里程',
    totalB:'近12个月充值里程总数',
    console:{

        titleOne:{
            realtime:'实时统计',
            type5:'总用户数',

            
            type4:'总里程',
           
            type6:'总用电量',
            type7:'订单总额',
            type8:'总订单量',

            
            type9:'总换电次数',

            type1:'电动车总数',
            type2:'换电柜总数',
            type3:'电池总数',
        },
        titleTwo:{
            realday:'今日实时',
            type5:'故障设备',
            type6:'告警次数',


            type1:'行车里程',
            type2:'新激活电动车',
            type3:'新上线换电柜',
            type4:'新增电池',
            type7:"新增订单",
            type8:'新增订单总额',
            type9:'用电量',
            type10:'换电次数',
           

        }
    },
    common:{
        tipTitle:'测试',
        tipName:'名称',
        tipContent:"内容",
        tipAverage:"",
        optionsele:{
            type1:'在线',
            type2:'离线',    
        }
    },
    options:{
        elecar:'电动车在线与离线的比例及数值',
        huan:'换电柜在线与离线的比例及数值',
        battery:'电池在线与离线的比例及数值',
        elecar:'近七天骑行里程',
        cycling:'近七天骑行车辆',
        orders:'近七天订单',
        hoursname:'24小时换电高峰',
        common:{
            type1:'在线',
            type2:'离线',  
        },
          
    },

    optionsHis:{
        shebeiM:'设备模块',
        userM:'用户模块',
        orderM:"订单模块",
        add:'新增',
        startTime:'开始日期',
        endTime:'结束日期',
        startMonth:'开始月份',
        endMonth:'结束月份',
        to:'至',
        select:'请选择',
        day:'日',
        month:'月',
        common:{
            sameday:'当天',
            total:'总数'
        },
        shebeis:{
            type1:'消费里程/充值里程',
            type2:'电动车',
            type3:'电池',
            type4:'运营换电柜',
            type5:'绑车用户/注册用户',
            type6:'订单数量/订单金额',
            type7:'订单金额',
            type8:'用电量',
            type9:'换电次数/换电充电柜数',    
            type10:'活跃车辆/换电车辆',
            type11:'充值里程套餐数量分布',
        },
        elecar:'电动车'
    },
    hotLang:{
        name:'近30天行车top20区域',
        ranking:'排名',
        frequency:'次数',
        province: '省份',
        city: '城市',
        Points:'区域',
        provinceTitle: '近30天省份换电次数前20名',
        cityTitle: '近30天城市换电次数前20名',
        pointsTitle: '近30天区域换电次数前20名',
    },

    layer:{
        toggle:'确定切换语言吗',
        tips:'提示'   
    },
    button:{
        ok:'确定',
        cancel:'取消'
    },

    left1:'近6月月消费里程/月充值里程',
    left1Explanation:'统计近6个月每月车辆消费的里程数总和/每月用户充值的里程数总和。',
    left2:'近6月日活跃车辆/日换电车辆',
    left2Explanation:'统计近6个月每月平均日活跃的车辆数（有骑行记录)/日更换电池的车辆数。',
    left4: '近6月日换电次数/日换电充电柜数',
    left4Explanation: '统计近6个月每月平均日换电次数/日产生换电记录的换电柜数。',
    left3:'近6月月充值的订单/月订单总额',
    left3Explanation:'统计近6个月每月充值订单数总和/每月充值订单金额总和。',
    content1:'历史24小时换电高峰',
    content1Explanation:'累计历史每天24h每小时时间段内的换电次数总和。',
    right1:'实时统计',
    right2:'累计里程',
    right3:'今日里程',
    right4:'累计订单总额',
    right5:'今日金额',
    right6:'累计订单总量',
    right7:'今日订单',
 
    right9:'设备数据',
    right10:'电动车总量',
    right11:'活跃',
    // right12:'设备数据',
    right12:'新增',
    right13:'电动车在线',
    right14:'换电柜在线',
    right15:'电池在线',

    right16:'其他',

    right17:'用户总数',
    right25:'累计充电电量',
    right18:'耗电量',
    right21:'换电次数',

    right22:'警告次数:',
    right23:'故障次数:',

    right19:'电池总量',
    right20:'换电柜总量',
    right99:'数据平台',
    right100:'共减少碳排放',
    right26:'昨日新增',


    rightBottomOne:{
        batteryLocation:'电池库存占比',
        scooterLocation:'电动车库存占比',
        stationLocation:'换电柜库存占比'
    },
    inventoryRatioInfo:{
        Warehouse: '仓库(印尼)',
        Operation: '运营(印尼)',
        Maintenance: '检修(印尼)',
        Factory: '工厂(中国)',
        RD: '研发(中国)'
    },
    vehicles: '活跃',
    times: '次数',
    orders: '订单',
    bind: '已激活',
    Alive: '已激活',
    online: '在运营',
    expense: '消费',
    topup: '充值',
    activeVehicles: '活跃车辆数',
    swapVehicles: '换电车辆数',
    swapFrequencyA: '换电次数',
    swapStationsA: '充电柜数',
    swapFrequencyB: '近12个月换电次数总数',
    swapStationsB: '近12个月充电柜数总数',
    activeUsersA: '绑车用户新增',
    registeredUsersA: '注册用户新增',
    activeUsersB: '绑车用户总数',
    registeredUsersB: '注册用户总数',
    orderAmountA: '订单金额新增',
    orderQuantityA: '订单数量新增',
    orderAmountB: '近12个月订单金额总数',
    orderQuantityB: '近12个月订单数量总数',
    activationA: '新增激活',
    activationB: '激活总数',
    exchangeCabinet: '换电柜总数',
    exchangeCabinetOperation: '在运营换电柜总数',
    right24: '历史换电电量频次表',
    yesterday: '昨日',
    number: '数',
    scooterTotal: '电动车总数',
    activeVehiclesTotal: '近12个月活跃车辆数',
    swapVehiclesTotal: '近12个月换电车辆数',
    swapFrequency: '换电次数',
    swapStations: '换电柜数',
    swapStations: '换电柜',
    many:'近60天换电次数最多的换电柜top10',
    few: '近60天换电次数最少的换电柜top10',
    title1: '轨迹分布统计图',
    title2: '车辆实时分布图',
    title3: '换电柜分布图',
    title4: '车辆轨迹热力图',
    title7: '近30天换电次数分布图',
    title5: '统计电动车区域排名',
    vehicle: '辆',
    piece: '个',
    title6: '统计换电柜区域排名',
    cabinet1: '三口柜新增',
    cabinet2: '八口柜新增',
    cabinet3: '三口柜总数',
    cabinet4: '八口柜总数',
    centerMap1: '车辆',
    centerMap2: '站点',
    centerMap3: '电池数',
    centerMap4: '换电柜',
    
    centerMap5: '只显示站点',
    centerMap6: '只显示电池',
    centerMap7: '只显示电动车',
    centerMap8: '只显示换电柜',
    centerMap9: '全部',
    title8:'近30天换电次数排名'
}